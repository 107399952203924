import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
    matchPath,
    Redirect,
    Route as OriginalRoute,
    Switch,
    useLocation,
    useParams
} from 'react-router-dom';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import clsx from 'clsx';
import moment from 'moment';
import smartlookClient from 'smartlook-client';
import { DESKTOP_COLORS } from 'src/consts';
import { useQuery } from 'src/hooks/useQuery';
import { useResponsive } from 'src/hooks/useResponsive';
import { useRouteScenario } from 'src/hooks/useRouteScenario';
import { makeRouteScenario } from 'src/utils/route';
import { HttpClient } from 'components/http/HttpClient';
import { JourneyLandingPage } from 'components/journey/ui/LandingPage';
import { OrderScenario } from 'components/order/model/Order';
import { isQuickpayEnabled } from 'components/pay/model/isQuickpayEnabled';
import { getDateFormatByCountry, localConfig } from 'components/timeslots/helpers';
import {
    getLocalAuthentication,
    resetLocalAuthentication,
    setOnCloseRedirectUrl
} from 'components/user/localAuth';
import { CONTENT_MAX_WIDTH } from 'config/constants';
import i18n, { loadTranslations } from 'config/i18n';
import { ConfirmDialog } from 'lib/ConfirmDialog';
import logger from 'lib/logger';
import { Route } from 'lib/Route';
import { Throbber } from 'lib/Throbber';
import { isObjectId } from 'lib/typeInference';
import { useAuth } from 'lib/useAuth';
import { useLocalHistory } from 'lib/useLocalHistory';
import { UserRoute } from 'lib/UserRoute';
import { EmailCapturePage } from 'pages/email-capture';
import { TwoFactorAuthenticationPage } from 'pages/two-factor-authentication';
import {
    createGuestSession,
    getUserDetails,
    resetReturnUrl,
    setShowMarketing,
    updateUser
} from 'store/auth/authActions';
import { getLayout } from 'store/layout/layoutActions';
import { getCurrentLocation } from 'store/locations/locationActions';
import { checkLoadingImage, loadSettings } from 'store/settings/settingsActions';
import { ApplicationState } from 'store/store';
import { PayOperationStatusPage } from './pay/pay-operation';
import { PaySummaryPage } from './pay/summary';
import { ActivatePage } from './activate';
import { ActivityPage } from './activity';
import { AwardsPage } from './awards';
import { Basket } from './basket';
import { ViewBillPage } from './bill';
import { ForgotPasswordPage } from './forgot-password';
import { LocationsPage } from './locations';
import { MenuPage } from './menu';
import { OperationStatusPage } from './operation-status';
import { OrderEntryPage } from './order-entry';
import { OrderSummaryPage } from './order-summary';
import { PayPage } from './pay';
import { PaymentMethodsPage } from './payment-methods';
import { LocationRouteParams, ROUTES } from './routes';
import { SignInPage } from './signin';
import { SignUpPage } from './signup';
import { UserInfo } from './user-info';
import { Helmet } from 'react-helmet';
import { getGoogleTagsManagerScript } from 'lib/googleHelpers';
import { UserDataFormData, UserMissingDataScreen } from 'components/user/UserMissingDataScreen';
import { BottomDialog } from 'lib/BottomDialog';
import { userApi } from 'components/user/userApi';
import { isUserMissingName } from 'components/user/model/User';
import { SWEETWATERS_CONTAINER, SWEETWATERS_ID, TORTILLA_CONTAINER, TORTILLA_ID } from 'lib/useGAHelpers';
import { CookieConsentDialog, getConsentValue, hasConsentTo } from 'app/CookieConsentDialog';

// to prevent making get location with locationId=sign-in
const stopWords = ['user', 'status', 'order'];

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        mainBackground: {
            height: '100%'
        },
        rootWrapper: (props: {
            backgroundImg: string;
            isDesktop: boolean;
            isWhiteBackgroundOnDesktop: boolean;
        }) => ({
            height: '100%',
            overflowY: 'auto',
            ...(props.isDesktop &&
                (!!props.backgroundImg
                    ? {
                          backgroundImage: `url(${props.backgroundImg})`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center center'
                      }
                    : {
                          backgroundColor: DESKTOP_COLORS.BACKGROUND_GREY
                      })),
            ...(!props.backgroundImg &&
                props.isDesktop &&
                props.isWhiteBackgroundOnDesktop && {
                    backgroundColor: theme.palette.common.white
                })
        }),
        rootBox: {
            width: '100%',
            height: '100%',
            maxWidth: `${CONTENT_MAX_WIDTH}px`,
            margin: '0 auto'
        }
    })
);

const whiteBackgroundRoutes = [ROUTES.BASE];

const QUICKPAY_URLS = [ROUTES.QUICKPAY.CHECKOUT, ROUTES.QUICKPAY.STATUS, ROUTES.QUICKPAY.SUMMARY];
const AUTHORIZATION_URLS = [
    ROUTES.USER.LOGIN,
    ROUTES.USER.REGISTER,
    ROUTES.USER.ACTIVATE,
    ROUTES.USER.FORGOT_PASSWORD,
    ROUTES.USER.TWO_FACTOR_AUTHENTICATION
];

export const BasePage: React.FC = () => {
    const [isSessionExpired, setIsSessionExpired] = React.useState(false);
    const [scenario] = useRouteScenario();
    const { merchantId, locationId } = useParams<LocationRouteParams>();
    const { push, getParsedPath } = useLocalHistory();
    const queryLocationId = useQuery('locationId');
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { user, isGuest, showMarketing } = useAuth();
    const { pathname } = useLocation();
    const { hasSession } = useAuth();
    const { isDesktop } = useResponsive();
    const [isSmartlookSet, setIsSmartlookSet] = React.useState(false);
    const [consentVerified, setConsentVerified] = React.useState(false);
    const isQuickpayUrl = React.useMemo(
        () => QUICKPAY_URLS.some(item => matchPath(pathname, item)),
        [pathname]
    );
    const isV2Url = isQuickpayUrl;
    const isAuthroizationUrl = React.useMemo(
        () => AUTHORIZATION_URLS.some(item => matchPath(pathname, item)),
        [pathname]
    );
    const isWhiteBackgroundOnDesktop = React.useMemo(
        () =>
            whiteBackgroundRoutes.some(
                path =>
                    !!matchPath(pathname, {
                        path,
                        exact: true
                    })
            ),
        [pathname]
    );
    const classes = useStyles({
        backgroundImg: `${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/web_desktop_background.png`,
        isDesktop,
        isWhiteBackgroundOnDesktop
    });
    const { isLoading, settings, isInitiallyLoaded } = useSelector(
        (state: ApplicationState) => state.settings,
        (prevState, nextState) =>
            shallowEqual(prevState.settings, nextState.settings) &&
            prevState.isLoading === nextState.isLoading
    );
    const {
        isLoading: isLayoutLoading,
        tenantId: layoutTenantId,
        layout
    } = useSelector(
        (state: ApplicationState) => state.layout,
        (prevState, nextState) => prevState.isLoading === nextState.isLoading
    );
    const { currentLocation } = useSelector((state: ApplicationState) => state.locations);
    const [shouldCheckAgeVerification, setShouldCheckAgeVerification] = React.useState(
        scenario === OrderScenario.ORDER_TO_TABLE
    );
    const correctLocationId = React.useMemo(
        () =>
            (isObjectId(locationId) && locationId) ||
            (isObjectId(queryLocationId) && queryLocationId) ||
            'common',
        [locationId, queryLocationId]
    );
    const isValidSetting = React.useMemo(
        () =>
            correctLocationId !== 'common'
                ? settings?.locationId === correctLocationId
                : settings?.locationId === 'common',
        [correctLocationId, settings?.locationId]
    );

    React.useEffect(() => {
        if (
            settings?.smartlookEnabled &&
            process.env.SMARTLOOK_API_KEY &&
            !isSmartlookSet &&
            consentVerified &&
            merchantId
        ) {
            setIsSmartlookSet(true);
            const consentState = getConsentValue(merchantId);
            smartlookClient.init(process.env.SMARTLOOK_API_KEY, {
                cookies: !!consentState && hasConsentTo(consentState, 'analytics')
            });
        }
    }, [consentVerified, isSmartlookSet, merchantId, settings?.smartlookEnabled]);

    React.useEffect(() => {
        if (scenario === OrderScenario.ORDER_TO_TABLE) {
            setShouldCheckAgeVerification(true);
        }
    }, [scenario]);
    React.useEffect(() => {
        if (isObjectId(merchantId) && !!settings?.applicationId) {
            const favicon = document.getElementById('favicon') as HTMLLinkElement;
            const appleIcon = document.getElementById('apple-touch-icon') as HTMLLinkElement;
            checkLoadingImage()(dispatch);
            if (favicon) {
                // We are replacing favicon.href to use merchant based icon
                favicon.href = `${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/icon_40.png`;
            }
            if (appleIcon) {
                // We are replacing appleIcon.href to use merchant based icon (safari specific)
                appleIcon.href = `${process.env.MEDIA_URL}/tenants/${merchantId}/app_media/icon_40.png`;
            }
            if (!i18n.languages.includes(merchantId)) {
                loadTranslations(merchantId);
            }
        }
    }, [merchantId, settings?.applicationId, dispatch]);

    const handleUnauthorized = React.useCallback(() => {
        if (!isAuthroizationUrl) {
            if (!isGuest) {
                setIsSessionExpired(true);
            } else {
                createGuestSession(dispatch);
            }
        }
    }, [dispatch, isAuthroizationUrl, isGuest]);
    // To prevent event to be added multiple times
    const authorizationEventHandler = useRef(handleUnauthorized);

    const handleAgeVerificationConfirm = React.useCallback(() => setShouldCheckAgeVerification(false), []);
    const handleAgeVerificationCancel = React.useCallback(() => {
        push(ROUTES.JOURNEY.LANDING);
        setShouldCheckAgeVerification(false);
    }, [push]);

    React.useEffect(() => {
        HttpClient.events.removeListener('unauthorized', authorizationEventHandler.current);
        HttpClient.events.addListener('unauthorized', handleUnauthorized);
        authorizationEventHandler.current = handleUnauthorized;

        return () => {
            HttpClient.events.removeListener('unauthorized', handleUnauthorized);
        };
    }, [handleUnauthorized]);

    // Reset Login / Sign Up Redirect
    React.useEffect(() => resetReturnUrl(dispatch), [dispatch]);
    React.useEffect(() => {
        if (!user && settings && !isAuthroizationUrl) {
            const localAuthentication = getLocalAuthentication();

            if (localAuthentication) {
                getUserDetails(localAuthentication.userId, true)(dispatch);
            } else {
                createGuestSession(dispatch);
            }
        }
    }, [dispatch, isAuthroizationUrl, settings, user]);

    React.useEffect(() => {
        if (settings) {
            const dateFormat = getDateFormatByCountry(settings.region.code);
            moment.locale(settings.region.code || 'en', {
                ...localConfig,
                calendar: {
                    ...localConfig.calendar,
                    nextWeek: `dddd [at] ${dateFormat}`,
                    sameElse: dateFormat
                }
            });
        }
    }, [settings]);

    React.useEffect(() => {
        if (merchantId) {
            if (!isInitiallyLoaded || settings?.locationId !== correctLocationId) {
                if (isAuthroizationUrl && !!settings) {
                    return;
                }
                loadSettings(
                    merchantId,
                    correctLocationId
                )(dispatch).then(() => {
                    if (!layout || layoutTenantId !== merchantId) {
                        getLayout()(dispatch);
                    }
                });
            }
        }
    }, [
        merchantId,
        dispatch,
        locationId,
        isInitiallyLoaded,
        settings?.locationId,
        queryLocationId,
        layout,
        layoutTenantId,
        isAuthroizationUrl,
        settings,
        isGuest,
        settings?.region?.code,
        correctLocationId
    ]);

    React.useEffect(() => {
        if (
            !!locationId &&
            isObjectId(locationId) &&
            !stopWords.includes(locationId.toLowerCase()) &&
            !!settings?.locationId &&
            (!currentLocation || currentLocation?._id !== locationId)
        ) {
            getCurrentLocation(locationId)(dispatch);
        }
    }, [locationId, dispatch, settings?.locationId, currentLocation, currentLocation?._id]);

    const handleConfirm = React.useCallback(() => {
        if (!!merchantId) {
            if (!!locationId && !!scenario) {
                if ([OrderScenario.ORDER_TO_TABLE, OrderScenario.TABLE].includes(scenario)) {
                    setOnCloseRedirectUrl(getParsedPath(ROUTES.JOURNEY.LANDING), merchantId);
                } else {
                    setOnCloseRedirectUrl(getParsedPath(ROUTES.MENU, {}), merchantId);
                }
            } else {
                setOnCloseRedirectUrl(getParsedPath(ROUTES.BASE, { merchantId, locationId: '' }), merchantId);
            }
        }
        push(ROUTES.USER.LOGIN);
        resetLocalAuthentication();
        setIsSessionExpired(false);
        resetLocalAuthentication();
        dispatch(updateUser(undefined));
    }, [merchantId, push, dispatch, locationId, scenario, getParsedPath]);

    const isQuickpayAvailable = React.useMemo(() => isQuickpayEnabled(settings), [settings]);

    React.useEffect(() => {
        if (
            !!settings &&
            settings?.locationId === correctLocationId &&
            !isQuickpayAvailable &&
            isQuickpayUrl
        ) {
            push(ROUTES.JOURNEY.LANDING);
        }
    }, [correctLocationId, isQuickpayAvailable, isQuickpayUrl, push, settings, settings?.locationId]);

    const getMerchantSpecificHead = React.useCallback(() => {
        switch (merchantId) {
            case TORTILLA_ID:
                return (
                    <script type="text/javascript">{getGoogleTagsManagerScript(TORTILLA_CONTAINER)}</script>
                );
            case SWEETWATERS_ID:
                return (
                    <script type="text/javascript">
                        {getGoogleTagsManagerScript(SWEETWATERS_CONTAINER)}
                    </script>
                );
            default:
                return null;
        }
    }, [merchantId]);

    const showDataCollectionScreen = React.useMemo(() => {
        if (user && !isGuest) {
            if (
                isUserMissingName(user) ||
                (settings?.app?.ageRequestEnabled && !user.birthdate) ||
                showMarketing
            ) {
                return true;
            }
        }
        return false;
    }, [isGuest, settings?.app?.ageRequestEnabled, showMarketing, user]);

    const handleSubmitData = React.useCallback(
        async (data: UserDataFormData) => {
            try {
                if (user?._id) {
                    const result = await userApi.updateUser(user?._id, data);
                    dispatch(updateUser(result));
                }
            } catch (e: any) {
                logger.error(e.message, e);
            } finally {
                dispatch(setShowMarketing(false));
            }
        },
        [dispatch, user?._id]
    );

    const handleConsentVerified = React.useCallback(() => {
        setConsentVerified(true);
    }, []);

    if (
        !isV2Url &&
        (isLoading ||
            isLayoutLoading ||
            (!hasSession && !isAuthroizationUrl && !isSessionExpired) ||
            (!isValidSetting && !isAuthroizationUrl) ||
            (!hasSession && !isAuthroizationUrl))
    ) {
        return (
            <>
                <Throbber text={t('GENERAL_LOADING')} />
                <ConfirmDialog
                    open={isSessionExpired && !isAuthroizationUrl}
                    disableBackdropClick
                    disableEscapeKeyDown
                    onClose={handleConfirm}
                    confirmMessage={t('OK')}
                    title={t('DIALOG_OOPS')}
                    onConfirm={handleConfirm}
                    description={t('DIALOG_USER_SESSION_INVALID')}
                    isCancellable={false}
                />
            </>
        );
    }
    if (!isV2Url && !settings) {
        return t('GENERAL_CANNOT_LOAD', { instance: t('MANIFEST') });
    }

    return (
        <Box className={clsx(classes.mainBackground)}>
            <Box className={clsx(classes.rootWrapper, 'scroll-styled')}>
                <Helmet>{process.env.NODE_ENV === 'production' && getMerchantSpecificHead()}</Helmet>
                <Box className={classes.rootBox}>
                    <Switch>
                        <Route component={OrderEntryPage} path={ROUTES.ORDER.ENTRY} />
                        <Route component={LocationsPage} path={ROUTES.BASE} exact />
                        <Route useBackground component={SignInPage} path={ROUTES.USER.LOGIN} />
                        <Route
                            useBackground
                            component={TwoFactorAuthenticationPage}
                            path={ROUTES.USER.TWO_FACTOR_AUTHENTICATION}
                        />
                        <UserRoute useBackground path={ROUTES.USER.ACTIVITY}>
                            <ActivityPage />
                        </UserRoute>
                        <UserRoute useBackground fullHeight path={ROUTES.USER.PAYMENT_METHODS}>
                            <PaymentMethodsPage />
                        </UserRoute>
                        <UserRoute useBackground path={ROUTES.USER.DETAILS}>
                            <UserInfo />
                        </UserRoute>
                        <UserRoute useBackground path={ROUTES.USER.REWARDS}>
                            <AwardsPage />
                        </UserRoute>
                        <Route useBackground component={SignUpPage} path={ROUTES.USER.REGISTER} />
                        <Route useBackground component={ActivatePage} path={ROUTES.USER.ACTIVATE} />
                        <Route useBackground component={EmailCapturePage} path={ROUTES.USER.EMAIL_CAPTURE} />
                        <Route
                            useBackground
                            component={ForgotPasswordPage}
                            path={ROUTES.USER.FORGOT_PASSWORD}
                        />
                        <Route component={JourneyLandingPage} path={ROUTES.JOURNEY.LANDING} />
                        <Route useBackground component={OperationStatusPage} path={ROUTES.ORDER.OPERATION} />
                        <Route useBackground component={OrderSummaryPage} path={ROUTES.ORDER.SUMMARY} />
                        <Route useBackground fullHeight component={Basket} path={ROUTES.ORDER.BASKET} />
                        <OriginalRoute component={PayPage} path={ROUTES.QUICKPAY.CHECKOUT} />
                        <OriginalRoute component={PayOperationStatusPage} path={ROUTES.QUICKPAY.STATUS} />
                        <OriginalRoute component={PaySummaryPage} path={ROUTES.QUICKPAY.SUMMARY} />
                        {isQuickpayAvailable && (
                            <Redirect
                                from={ROUTES.JOURNEY.PAT.BILL}
                                to={getParsedPath(ROUTES.QUICKPAY.CHECKOUT, {
                                    scenario: makeRouteScenario(OrderScenario.TABLE)
                                })}
                            />
                        )}
                        <Route
                            useBackground
                            fullHeight
                            component={ViewBillPage}
                            path={ROUTES.JOURNEY.PAT.BILL}
                        />
                        <Redirect
                            from={ROUTES.JOURNEY.PAT.BILL_WITHOUT_SCENARIO}
                            to={getParsedPath(ROUTES.JOURNEY.PAT.BILL, {
                                scenario: makeRouteScenario(OrderScenario.TABLE)
                            })}
                        />
                        <Route component={MenuPage} path={ROUTES.MENU} exact />
                        <Redirect
                            from={ROUTES.MENU_WITHOUT_SCENARIO}
                            to={getParsedPath(ROUTES.MENU, {
                                scenario: makeRouteScenario(OrderScenario.PREORDER)
                            })}
                        />
                        <Redirect to={ROUTES.BASE} />
                    </Switch>
                    <BottomDialog open={showDataCollectionScreen}>
                        <UserMissingDataScreen
                            onSubmit={handleSubmitData}
                            birthdateEnabled={settings?.app?.ageRequestEnabled}
                            birthdateRequired={(settings?.app?.registrationMinimumAge ?? 0) > 0}
                            user={user}
                        />
                    </BottomDialog>
                    <ConfirmDialog
                        open={isSessionExpired && !isAuthroizationUrl}
                        disableBackdropClick
                        disableEscapeKeyDown
                        onClose={handleConfirm}
                        confirmMessage={t('OK')}
                        title={t('DIALOG_OOPS')}
                        onConfirm={handleConfirm}
                        description={t('DIALOG_USER_SESSION_INVALID')}
                        isCancellable={false}
                    />
                    <ConfirmDialog
                        title={t('OTT_AGE_MESSAGE_TITLE')}
                        description={t('OTT_AGE_MESSAGE')}
                        confirmMessage={t('OTT_AGE_CONFIRM')}
                        cancelMessage={t('OTT_AGE_CANCEL')}
                        onConfirm={handleAgeVerificationConfirm}
                        onClose={handleAgeVerificationCancel}
                        open={shouldCheckAgeVerification}
                        disableEscapeKeyDown
                        disableBackdropClick
                    />
                </Box>
            </Box>
            <CookieConsentDialog onVerify={handleConsentVerified} />
        </Box>
    );
};
